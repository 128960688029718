import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

import Modal from "./components/modal";
import { Button } from "./components/modal/modal.styles";

var crypto = require("crypto");


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  padding: 20px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton2 = styled.a`
  padding: 30px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 30px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--primary-text);
  width: 400px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton3 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton4 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--accent);
  padding: 10px;
  font-family: 'Press Start 2P';
  color: var(--new-text);
  width: 300px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 1000px;
  min-height: 100px;
  max-height: 1000px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledImg2 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 128px;
  min-height: 100px;
  max-height: 192px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledImg3 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 256px;
  min-height: 100px;
  max-height: 384px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  padding: 10px;
  font-family: 'Press Start 2P';
  color: var(--accent);
  text-decoration: none;
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You are voting with the power of Potatoes and Vote Tokens. Voting does NOT burn any tokens. You can vote for ONE project.`);

  const [potatoAmount, setPotatoAmount] = useState(1);
  const [costonAmount, setCostonAmount] = useState(1);

  // modal
  const [active, setActive] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },

    NFT_NAME_POTATO: "",
    SYMBOL_POTATO: "",
    GAS_LIMIT_POTATO: 0,

    NFT_NAME_COSTON: "",
    SYMBOL_COSTON: "",
    GAS_LIMIT_COSTON: 0,

    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });



  const vote1 = () => {
    let gasLimit = CONFIG.GAS_LIMIT_POTATO;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Voting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .vote(1)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. You might have voted already, or have no vote tokens. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have voted!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const vote2 = () => {
    let gasLimit = CONFIG.GAS_LIMIT_POTATO;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Voting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .vote(2)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. You might have voted already, or have no vote tokens. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have voted!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const vote3 = () => {
    let gasLimit = CONFIG.GAS_LIMIT_POTATO;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Voting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .vote(3)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. You might have voted already, or have no vote tokens. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have voted!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const vote4 = () => {
    let gasLimit = CONFIG.GAS_LIMIT_POTATO;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Voting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .vote(4)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. You might have voted already, or have no vote tokens. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have voted!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const vote5 = () => {
    let gasLimit = CONFIG.GAS_LIMIT_POTATO;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Voting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .vote(5)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. You might have voted already, or have no vote tokens. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have voted!`
        );

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };



  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  
  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "" : null}
      >


        {/* TITLE */}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              SGB CRAFT VOTING MACHINE
              <br></br>
            </s.MainTitle>

            <s.SpacerSmall />
            
            <s.ContractAddress
              style={{
                textAlign: "center",
                color: "var(--primary)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.ContractAddress>

            <s.SpacerSmall />

            <s.TextDescription style={{ textAlign: "center", color: "var(--new-text)", }}>
              For holders of SGB Craft Vote Tokens Only
              <br></br>
              Token ID: 51
              <br></br>
            </s.TextDescription>

            <s.SpacerSmall />

          </s.Container>

        </ResponsiveWrapper>


        {/* TITLE 2 */}
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>

            <s.SpacerSmall />

            <s.TextDescription style={{ textAlign: "center", color: "var(--new-text)", }}>
              Please make sure you are connected to the right network (
              {CONFIG.NETWORK.NAME} Mainnet).
              <br></br><br></br>
              Vote for your favourite Community Project to be included in SGB CRAFT
              <br></br><br></br>
              We use {CONFIG.GAS_LIMIT_POTATO} gas limit.
            </s.TextDescription>

            {/* VOTE COUNTER */}
            <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

            <s.Container flex={1} jc={"top"} ai={"center"}
              style={{
                backgroundColor: "var(--accent-text2)",
                padding: 15,
                borderRadius: 24,
                border: "4px  var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              }}
              id={"standard"}
            >

              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                You have {data.totalSupply[0]} Potatoes and {data.totalSupply[1]} Vote Tokens
                <br></br><br></br>Total of {data.totalSupply[2]} voting power
                <br></br><br></br>{data.totalSupply[2] - data.totalSupply[3]} available votes
              </s.TextTitle>

              <s.SpacerXSmall />

              <span
                style={{
                  textAlign: "center",
                }}
              >
              <StyledImg2 src={"/config/images/gif1.gif"} />
              <StyledImg2 src={"/config/images/votertoken.png"} />
              </span>
              

              <s.SpacerXSmall />

              {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--primary)" }}
                  >
                    You have already voted, or have no Vote Tokens.
                  </s.TextTitle>

                </>
              ) : (
                <>

                  <s.SpacerXSmall />

                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT SONGBIRD
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>

                    </>
                  )}
                </>
              )}
              <s.SpacerSmall />

            </s.Container>

            </s.Container>


            <s.SpacerSmall />

            {/* START CONTENT */}
            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {/* VOTE COUNTER */}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--new-text)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.SpacerXSmall />

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >
                  <StyledImg2 src={"/config/images/gif1.gif"} />
                  <StyledImg2 src={"/config/images/votertoken.png"} />
                  </span>

                  <s.SpacerXSmall />

                  {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--primary)" }}
                      >
                        You have already voted, or have no Vote Tokens.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>

                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>


              {/* XWHALE OPTION*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                
                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent-text2)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[4]} Votes
                    <br></br> for XWHALES/SGB WHALES
                  </s.TextTitle>
                  
                  <s.SpacerXSmall />
                  
                  <StyledImg2 src={"/config/images/whale-pic.jpeg"} />

                  <s.SpacerXSmall />
                  
                  {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have already voted, or have no Vote Tokens.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          
                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {data.totalSupply[2] - data.totalSupply[3]} available votes
                            </s.TextDescription>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                vote1();
                                getData();
                              }}
                            >
                              {claimingNft ? "VOTING..." : "VOTE"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>


              {/* SGBEE-BARNEY OPTION*/}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                
                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent-text2)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[5]} Votes
                    <br></br> for SGBEE/BARNEY
                  </s.TextTitle>
                  
                  <s.SpacerXSmall />

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >
                  <StyledImg2 src={"/config/images/sgbee-pic.jpeg"} />
                  <StyledImg2 src={"/config/images/barney-pic.jpeg"} />
                  </span>

                  <s.SpacerXSmall />
                  
                  {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have already voted, or have no Vote Tokens.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          
                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {data.totalSupply[2] - data.totalSupply[3]} available votes
                            </s.TextDescription>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                vote2();
                                getData();
                              }}
                            >
                              {claimingNft ? "VOTING..." : "VOTE"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

            </ResponsiveWrapper>

            <s.SpacerSmall />

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {/* GRUNGIE OPTION */}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                
                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent-text2)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[6]} Votes
                    <br></br> for THE GRUNGIES
                  </s.TextTitle>
                  
                  <s.SpacerXSmall />
                  
                  <StyledImg2 src={"/config/images/grungie-pic.jpeg"} />

                  <s.SpacerXSmall />
                  
                  {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have already voted, or have no Vote Tokens.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          
                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {data.totalSupply[2] - data.totalSupply[3]} available votes
                            </s.TextDescription>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                vote3();
                                getData();
                              }}
                            >
                              {claimingNft ? "VOTING..." : "VOTE"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>


              {/* CANARY PUNKS OPTION */}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                
                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent-text2)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[7]} Votes
                    <br></br> for CANARY PUNKS
                  </s.TextTitle>
                  
                  <s.SpacerXSmall />
                  
                  <StyledImg2 src={"/config/images/canarypunks-pic.jpeg"} />

                  <s.SpacerXSmall />
                  
                  {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have already voted, or have no Vote Tokens.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          
                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {data.totalSupply[2] - data.totalSupply[3]} available votes
                            </s.TextDescription>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                vote4();
                                getData();
                              }}
                            >
                              {claimingNft ? "VOTING..." : "VOTE"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>


              {/* STOADZ OPTION */}
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                
                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--accent-text2)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[8]} Votes
                    <br></br> for XTOADZ/STOADZ
                  </s.TextTitle>
                  
                  <s.SpacerXSmall />
                  
                  <StyledImg2 src={"/config/images/xtoadz-pic.png"} />

                  <s.SpacerXSmall />
                  
                  {Number(data.totalSupply[2] - data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have already voted, or have no Vote Tokens.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          
                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {data.totalSupply[2] - data.totalSupply[3]} available votes
                            </s.TextDescription>
                          </s.Container>

                          <s.SpacerSmall />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                vote5();
                                getData();
                              }}
                            >
                              {claimingNft ? "VOTING..." : "VOTE"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

            </ResponsiveWrapper>

            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>

        {/* FOOTER */}                      
        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

        </ResponsiveWrapper>

        <s.SpacerMedium />

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

          <s.SpacerSmall />

        </s.Container>

      </s.Container>
    </s.Screen>
  );
}

export default App;