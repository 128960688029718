// log
import store from "../store";
import Web3 from "web3";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let currentAddress = await store.getState().blockchain.account;

      let thisAddressPotatoBalance = await store.getState().blockchain.smartContract.methods.checkPotatoes(currentAddress).call(); // gets vote tokens owned by user
      let thisAddressTokenBalance = await store.getState().blockchain.smartContract.methods.checkVoteTokens(currentAddress).call(); // gets vote tokens owned by user
      let thisAddressAvailableVotes = await store.getState().blockchain.smartContract.methods.checkAvailableVotes(currentAddress).call(); // gets vote tokens owned by user
      let thisAddressVotedCounter = await store.getState().blockchain.smartContract.methods.addressVotedTokenCounter(currentAddress).call(); // if not voted yet, returns 0

      let votesForOption1 = await store.getState().blockchain.smartContract.methods.voteCounter(1).call();
      let votesForOption2 = await store.getState().blockchain.smartContract.methods.voteCounter(2).call();
      let votesForOption3 = await store.getState().blockchain.smartContract.methods.voteCounter(3).call();
      let votesForOption4 = await store.getState().blockchain.smartContract.methods.voteCounter(4).call();
      let votesForOption5 = await store.getState().blockchain.smartContract.methods.voteCounter(5).call();

      let totalSupply = [thisAddressPotatoBalance, thisAddressTokenBalance, thisAddressAvailableVotes, thisAddressVotedCounter, votesForOption1, votesForOption2, votesForOption3, votesForOption4, votesForOption5];
      // 0 - potato count
      // 1 - votetoken count
      // 2 - available votes
      // 3 - voted counter
      // 4 - vote1
      // 5 - vote2
      // 6 - vote3
      // 7 - vote4
      // 8 - vote5
      
      dispatch(
        fetchDataSuccess({
          totalSupply,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};